<template>
    <div id="About" ref="About" class="items-center justify-center min-w-full min-h-full p-3  ">
      <div class="border border-black rounded-sm p-4">
        <div class="text-left font-semibold font-cv pb-4 text-2xl text-gray-600 " data-aos="flip-up" data-aos-duaration="4000" data-aos-delay="0">—  About —</div>
        <div class="md:flex md:flex-row">
      <img src="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/Screenshot%202024-03-21%20215742.png?alt=media&token=aff837f3-9e00-485f-bed7-b5bdaf8e9558" class="shadow-lg sm:w-1/2 object-cover rounded" data-aos="flip-left" data-aos-duaration="4000" data-aos-delay="600" />
      <div class="md:pl-7 sm:gap-y-44 md:text-2xl text-1xl flex-row font-subtitle  text-black pt-3 text-start" data-aos="fade-up"  data-aos-delay="800">
   Sustain Nova: Forging a Future of Sustainability and Innovation through design thinking. From pioneering breakthroughs in healthcare, agriculture, and impactful social projects to shaping visionary business strategies, we turn dreams into reality. Our commitment extends beyond sustainability; it's about crafting a tomorrow that's truly transformative. Join us on this journey where progress meets purpose, and innovation finds its heartbeat in every idea we nurture.
      </div>
    </div>
    </div>
  </div>
  
    
  </template>
  
  <script>
  import tw from "@/components/Animation_tw.vue"
  import fadein from "@/components/Animation_fadein.vue"
  export default {
    components:{
      tw,
      fadein
    }
  };
  </script>
  