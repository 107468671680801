<template>
 
  <div ref="home" id="home" class="flex-col h-screen w-full bg-black">
    <div class="absolute w-full h-screen flex items-center justify-center">
      <img class="object-cover h-full w-full bg-transparent opacity-65" src="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/home.png?alt=media&token=99469b47-c47a-403b-a02f-e117733ba489" alt="Background Image">
    </div>
    <div class="relative min-h-full flex flex-col justify-center items-center">
      <div class="font-title md:text-3xl text-3xl text-center text-white font-medium ">
        <tw text="Welcome To" typingSpeed="120" />
      </div>
      <div class="font-subtitle text-3xl text-center text-white font-bold pt-2">
        <fadein text="Sustain Nova Tech Labs" delay="1300" />
      </div>
      <div class="font-light font-Kalam text-base text-center text-white pt-1">
        <fadein text="Transforming dreams and ideas into tangible, meaningful outcomes" delay="1800" />
      </div>
      <div class="justify-center pt-8 animate-bounce flex">
        <button type="button" @click="scrollTo('contact')"  class="py-3 px-20 justify-center items-center gap-x-2 font-title text-lg font-medium rounded-lg border border-transparent bg-orange-500 text-white disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none transition duration-500">
          Get Started
        </button>
      </div>
    </div>
  </div>
 </template>
 
 <script>
 import navigate from '@/components/navigation.vue';
 import fadein from '@/components/Animation_fadein.vue';
 import tw from '@/components/Animation_tw.vue';

 export default {
  components: {
    navigate,
    fadein,
    tw,
   
  },
  methods: {
  scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop-70,
          behavior: 'smooth' ,
          duration:1000,
        
        });
      }
    }
  }
 }
 </script>
 
 <style scoped>
 .animate-bounce {
  animation-delay: 2000ms;
  animation-iteration-count: 2;
 }
 </style>