<template>
    
      <span
        v-for="(letter, index) in animatedText"
        :key="index"
        :class="[
          'inline-block',
          'transition-all',
          'duration-500',
          'ease-in-out',
          `delay-${index * 100}`,
          isAnimated ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0',
        ]"
      >
        {{ letter }}
      </span>
  
  </template>
  
  <script>
  export default {
    props: {
      text: {
        type: String,
        required: true,
      },
      delay: {
        type: Number,
        default: 100,
      },
    },
    data() {
      return {
        isAnimated: false,
      };
    },
    mounted() {
      this.startAnimation();
    },
    computed: {
      animatedText() {
        return this.text.split()
      },
    },
    methods: {
      startAnimation() {
        setTimeout(() => {
          this.isAnimated = true;
        }, this.delay);
      },
    },
  };
  </script>