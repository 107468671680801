<template>
    <div id="products" ref="produts" class="p-5 flex-col items-center">
      <div class="font-medium text-2xl text-gray-600 font-cv">
        — Our Produts —
      </div>
      <div class="md:flex md:flex-row md:justify-between lg:justify-center md:items-start">
        <div class="lg:flex lg:flex-row lg:justify-start lg:items-start">
        <card
          imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/agree.png?alt=media&token=491db9c4-6a66-4e44-b3d9-c65d65a14da9"
          delay="0"
          title="Agriculture"
          subtitle="Revolutionizing Agri-Tech Solutions"
          fullText="Discover intelligent and sustainable solutions for contemporary , elevating productivity and promoting eco-friendly practices. Our mission is to deliver profitable agricultural solutions for businesses and innovative products that revolutionize the  sector."
        />
        <card
          imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/hc.png?alt=media&token=f9db76d9-7ea9-460e-8e67-1704395f0977"
          delay="200"
          title="Health care"
          subtitle="Shaping a Healthier Tomorrow"
          fullText="Embark on the healthcare revolution with our cutting-edge solutions, seamlessly integrating technology for enhanced patient care and the development of disability-assisting aids and devices. We specialize in building implant prototyping solutions through advanced 3D printing models, shaping the future of healthcare innovation."
        />
      </div>
      <div class="lg:flex lg:flex-row lg:justify-start lg:items-start">
        <card
          imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/ip.png?alt=media&token=2cf3baa8-d913-49cb-8be1-85708ca25f37"
          title="Industrial project"
          delay="400"
          subtitle="Innovate, Build, Transform"
          fullText="Elevate your industrial ventures with our cutting-edge solutions, dedicated to developing effective automation devices. We aim to optimize efficiency, increase revenue, and ensure customer satisfaction through innovative industrial solutions."
        />
        <card
          imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/Screenshot%202024-04-11%20184718.png?alt=media&token=0906f73b-f6a2-4bec-b7f6-238089928f8e"
          title="Social project"
          delay="600"
          subtitle="Empowering communities"
          fullText="Join us in impactful social projects dedicated to making a positive difference in communities, empowering women with hygienic environments. Together, we invest in building a smarter society, one initiative at a time."
        />
      </div>
      </div>
    </div>
  </template>
  
  <script>
  import card from './container.vue'
  
  export default {
    components: {
      card
    }
  }
  </script>