<template>
    <div class="bg-black h-screen">
      <img src="https://img.freepik.com/free-photo/glowing-circles-ignite-fantasy-vibrant-blue-generated-by-ai_24640-83116.jpg?t=st=1712075368~exp=1712078968~hmac=c191eaabce11d93e8baa8868450e9b517943275d8f05b19916128d8109c88dba&w=1060"
           class="w-full h-full opacity-30 object-cover absolute">
      <div class="relative">
        <div class="text-5xl text-center font-subtitle pt-5 text-white"> NSV RUBBER STAMPS</div>
        <div class="text-2xl text-center font-subtitle pt-2 text-green-200 font-bold">STMZH Text Converter</div>
        <div class="flex flex-row items-center justify-between w-full p-10">
          <div class="flex flex-col w-full justify-center items-center">
            <label for="unicode" class="block text-sm mb-2 text-white text-center font-cv">UNICODE TEXT</label>
            <textarea id="unicode" v-model="unicodeText" @input="convertText" class="bg-transparent py-3 px-4 block border h-1/2 w-10/12 border-white rounded-lg text-2xl text-white" rows="10" placeholder="Type Here ...."></textarea>
          </div>
          <div class="flex flex-col w-full justify-center items-center">
            <label for="stmz" class="block text-sm font-medium mb-2 text-white font-cv text-center">STMZH TEXT</label>
            <textarea id="stmz" v-model="stmzText" class="bg-transparent py-3 px-4 block h-1/2 w-10/12 border text-white border-white rounded-lg text-2xl" rows="10" placeholder="Say hi..."></textarea>
          </div>
        </div>
  
        <!-- <div class="flex justify-center items-center">
          <button @click="copyText" type="button" class="justify-center font-subtitle py-3 px-16 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-white text-white hover:border-white/70 hover:text-white/70 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
            Copy
          </button>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        unicodeText: '',
        stmzText: ''
      }
    },
    methods: {
      convertText() {
        this.stmzText = this.conversion(this.unicodeText);
        console.log(this.stmzText)
      },

      conversion(i){
var input;
input =i ;
input = input.replace(/௸/g, "");
input = input.replace(/ஸ்ரீ/g, "");

input = input.replace(/க்ஷௌ/g, "");
input = input.replace(/க்ஷோ/g, "");
input = input.replace(/க்ஷொ/g, "");
input = input.replace(/க்ஷா/g, "");
input = input.replace(/க்ஷி/g, "");
input = input.replace(/க்ஷீ/g, "");
input = input.replace(/க்ஷு/g, "");
input = input.replace(/க்ஷூ/g, "");
input = input.replace(/க்ஷெ/g, "");
input = input.replace(/க்ஷே/g, "");
input = input.replace(/க்ஷை/g, "");
input = input.replace(/க்ஷ்/g, "");
input = input.replace(/க்ஷ/g, "");
	
input = input.replace(/ஜௌ/g, "");
input = input.replace(/ஜோ/g, "");
input = input.replace(/ஜொ/g, "");
input = input.replace(/ஜா/g, "");
input = input.replace(/ஜி/g, "");
input = input.replace(/ஜீ/g, "");
input = input.replace(/ஜு/g, "");
input = input.replace(/ஜூ/g, "");
input = input.replace(/ஜெ/g, "");
input = input.replace(/ஜே/g, "");
input = input.replace(/ஜை/g, "");
input = input.replace(/ஜ்/g, "");
input = input.replace(/ஜ/g, "");

input = input.replace(/கௌ/g, "");
input = input.replace(/கோ/g, "");
input = input.replace(/கொ/g, "");
input = input.replace(/கா/g, "");
input = input.replace(/கி/g, "");
input = input.replace(/கீ/g, "");
input = input.replace(/கு/g, "");
input = input.replace(/கூ/g, "");
input = input.replace(/கெ/g, "");
input = input.replace(/கே/g, "");
input = input.replace(/கை/g, "");
input = input.replace(/க்/g, "");
input = input.replace(/க/g, "");

input = input.replace(/ஙௌ/g, "");
input = input.replace(/ஙோ/g, "");
input = input.replace(/ஙொ/g, "");
input = input.replace(/ஙா/g, "");
input = input.replace(/ஙி/g, "");
input = input.replace(/ஙீ/g, "");
input = input.replace(/ஙு/g, "");
input = input.replace(/ஙூ/g, "");
input = input.replace(/ஙெ/g, "");
input = input.replace(/ஙே/g, "");
input = input.replace(/ஙை/g, "");
input = input.replace(/ங்/g, "");
input = input.replace(/ங/g, "");

input = input.replace(/சௌ/g, "");
input = input.replace(/சோ/g, "");
input = input.replace(/சொ/g, "");
input = input.replace(/சா/g, "");
input = input.replace(/சி/g, "");
input = input.replace(/சீ/g, "");
input = input.replace(/சு/g, "");
input = input.replace(/சூ/g, "");
input = input.replace(/செ/g, "");
input = input.replace(/சே/g, "");
input = input.replace(/சை/g, "");
input = input.replace(/ச்/g, "");
input = input.replace(/ச/g, "");

input = input.replace(/ஞௌ/g, "");
input = input.replace(/ஞோ/g, "");
input = input.replace(/ஞொ/g, "");
input = input.replace(/ஞா/g, "");
input = input.replace(/ஞி/g, "");
input = input.replace(/ஞீ/g, "");
input = input.replace(/ஞு/g, "");
input = input.replace(/ஞூ/g, "");
input = input.replace(/ஞெ/g, "");
input = input.replace(/ஞே/g, "");
input = input.replace(/ஞை/g, "");
input = input.replace(/ஞ்/g, "");
input = input.replace(/ஞ/g, "");

input = input.replace(/டௌ/g, "");
input = input.replace(/டோ/g, "");
input = input.replace(/டொ/g, "");
input = input.replace(/டா/g, "");
input = input.replace(/டி/g, "");
input = input.replace(/டீ/g, "");
input = input.replace(/டு/g, "");
input = input.replace(/டூ/g, "");
input = input.replace(/டெ/g, "");
input = input.replace(/டே/g, "");
input = input.replace(/டை/g, "");
input = input.replace(/ட்/g, "");
input = input.replace(/ட/g, "");

input = input.replace(/ணௌ/g, "");
input = input.replace(/ணோ/g, "");
input = input.replace(/ணொ/g, "");
input = input.replace(/ணா/g, "");
input = input.replace(/ணி/g, "");
input = input.replace(/ணீ/g, "");
input = input.replace(/ணு/g, "");
input = input.replace(/ணூ/g, "");
input = input.replace(/ணெ/g, "");
input = input.replace(/ணே/g, "");
input = input.replace(/ணை/g, "");
input = input.replace(/ண்/g, "");
input = input.replace(/ண/g, "");

input = input.replace(/தௌ/g, "");
input = input.replace(/தோ/g, "");
input = input.replace(/தொ/g, "");
input = input.replace(/தா/g, "");
input = input.replace(/தி/g, "");
input = input.replace(/தீ/g, "");
input = input.replace(/து/g, "");
input = input.replace(/தூ/g, "");
input = input.replace(/தெ/g, "");
input = input.replace(/தே/g, "");
input = input.replace(/தை/g, "");
input = input.replace(/த்/g, "");
input = input.replace(/த/g, "");

input = input.replace(/நௌ/g, "");
input = input.replace(/நோ/g, "");
input = input.replace(/நொ/g, "");
input = input.replace(/நா/g, "");
input = input.replace(/நி/g, "");
input = input.replace(/நீ/g, "");
input = input.replace(/நு/g, "");
input = input.replace(/நூ/g, "");
input = input.replace(/நெ/g, "");
input = input.replace(/நே/g, "");
input = input.replace(/நை/g, "");
input = input.replace(/ந்/g, "");
input = input.replace(/ந/g, "");

input = input.replace(/னௌ/g, "");
input = input.replace(/னோ/g, "");
input = input.replace(/னொ/g, "");
input = input.replace(/னா/g, "");
input = input.replace(/னி/g, "");
input = input.replace(/னீ/g, "");
input = input.replace(/னு/g, "");
input = input.replace(/னூ/g, "");
input = input.replace(/னெ/g, "");
input = input.replace(/னே/g, "");
input = input.replace(/னை/g, "");
input = input.replace(/ன்/g, "");
input = input.replace(/ன/g, "");

input = input.replace(/பௌ/g, "");
input = input.replace(/போ/g, "");
input = input.replace(/பொ/g, "");
input = input.replace(/பா/g, "");
input = input.replace(/பி/g, "");
input = input.replace(/பீ/g, "");
input = input.replace(/பு/g, "");
input = input.replace(/பூ/g, "");
input = input.replace(/பெ/g, "");
input = input.replace(/பே/g, "");
input = input.replace(/பை/g, "");
input = input.replace(/ப்/g, "");
input = input.replace(/ப/g, "");

input = input.replace(/மௌ/g, "");
input = input.replace(/மோ/g, "");
input = input.replace(/மொ/g, "");
input = input.replace(/மா/g, "");
input = input.replace(/மி/g, "");
input = input.replace(/மீ/g, "");
input = input.replace(/மு/g, "");
input = input.replace(/மூ/g, "");
input = input.replace(/மெ/g, "");
input = input.replace(/மே/g, "");
input = input.replace(/மை/g, "");
input = input.replace(/ம்/g, "");
input = input.replace(/ம/g, "");

input = input.replace(/யௌ/g, "");
input = input.replace(/யோ/g, "");
input = input.replace(/யொ/g, "");
input = input.replace(/யா/g, "");
input = input.replace(/யி/g, "");
input = input.replace(/யீ/g, "");
input = input.replace(/யு/g, "");
input = input.replace(/யூ/g, "");
input = input.replace(/யெ/g, "");
input = input.replace(/யே/g, "");
input = input.replace(/யை/g, "");
input = input.replace(/ய்/g, "");
input = input.replace(/ய/g, "");

input = input.replace(/ரௌ/g, "");
input = input.replace(/ரோ/g, "");
input = input.replace(/ரொ/g, "");
input = input.replace(/ரா/g, "");
input = input.replace(/ரி/g, "");
input = input.replace(/ரீ/g, "");
input = input.replace(/ரு/g, "");
input = input.replace(/ரூ/g, "");
input = input.replace(/ரெ/g, "");
input = input.replace(/ரே/g, "");
input = input.replace(/ரை/g, "");
input = input.replace(/ர்/g, "");
input = input.replace(/ர/g, "");

input = input.replace(/லௌ/g, "");
input = input.replace(/லோ/g, "");
input = input.replace(/லொ/g, "");
input = input.replace(/லா/g, "");
input = input.replace(/லி/g, "");
input = input.replace(/லீ/g, "");
input = input.replace(/லு/g, "");
input = input.replace(/லூ/g, "");
input = input.replace(/லெ/g, "");
input = input.replace(/லே/g, "");
input = input.replace(/லை/g, "");
input = input.replace(/ல்/g, "");
input = input.replace(/ல/g, "");

input = input.replace(/ளௌ/g, "");
input = input.replace(/ளோ/g, "");
input = input.replace(/ளொ/g, "");
input = input.replace(/ளா/g, "");
input = input.replace(/ளி/g, "");
input = input.replace(/ளீ/g, "");
input = input.replace(/ளு/g, "");
input = input.replace(/ளூ/g, "");
input = input.replace(/ளெ/g, "");
input = input.replace(/ளே/g, "");
input = input.replace(/ளை/g, "");
input = input.replace(/ள்/g, "");
input = input.replace(/ள/g, "");

input = input.replace(/வௌ/g, "");
input = input.replace(/வோ/g, "");
input = input.replace(/வொ/g, "");
input = input.replace(/வா/g, "");
input = input.replace(/வி/g, "");
input = input.replace(/வீ/g, "");
input = input.replace(/வு/g, "");
input = input.replace(/வூ/g, "");
input = input.replace(/வெ/g, "");
input = input.replace(/வே/g, "");
input = input.replace(/வை/g, "");
input = input.replace(/வ்/g, "");
input = input.replace(/வ/g, "");

input = input.replace(/ழௌ/g, "");
input = input.replace(/ழோ/g, "");
input = input.replace(/ழொ/g, "");
input = input.replace(/ழா/g, "");
input = input.replace(/ழி/g, "");
input = input.replace(/ழீ/g, "");
input = input.replace(/ழு/g, "");
input = input.replace(/ழூ/g, "");
input = input.replace(/ழெ/g, "");
input = input.replace(/ழே/g, "");
input = input.replace(/ழை/g, "");
input = input.replace(/ழ்/g, "");
input = input.replace(/ழ/g, "");

input = input.replace(/றௌ/g, "");
input = input.replace(/றோ/g, "");
input = input.replace(/றொ/g, "");
input = input.replace(/றா/g, "");
input = input.replace(/றி/g, "");
input = input.replace(/றீ/g, "");
input = input.replace(/று/g, "");
input = input.replace(/றூ/g, "");
input = input.replace(/றெ/g, "");
input = input.replace(/றே/g, "");
input = input.replace(/றை/g, "");
input = input.replace(/ற்/g, "");
input = input.replace(/ற/g, "");

input = input.replace(/ஹௌ/g, "");
input = input.replace(/ஹோ/g, "");
input = input.replace(/ஹொ/g, "");
input = input.replace(/ஹா/g, "");
input = input.replace(/ஹி/g, "");
input = input.replace(/ஹீ/g, "");
input = input.replace(/ஹு/g, "");
input = input.replace(/ஹூ/g, "");
input = input.replace(/ஹெ/g, "");
input = input.replace(/ஹே/g, "");
input = input.replace(/ஹை/g, "");
input = input.replace(/ஹ்/g, "");
input = input.replace(/ஹ/g, "");

input = input.replace(/ஷௌ/g, "");
input = input.replace(/ஷோ/g, "");
input = input.replace(/ஷொ/g, "");
input = input.replace(/ஷா/g, "");
input = input.replace(/ஷி/g, "");
input = input.replace(/ஷீ/g, "");
input = input.replace(/ஷு/g, "");
input = input.replace(/ஷூ/g, "");
input = input.replace(/ஷெ/g, "");
input = input.replace(/ஷே/g, "");
input = input.replace(/ஷை/g, "");
input = input.replace(/ஷ்/g, "");
input = input.replace(/ஷ/g, "");

input = input.replace(/ஸௌ/g, "");
input = input.replace(/ஸோ/g, "");
input = input.replace(/ஸொ/g, "");
input = input.replace(/ஸா/g, "");
input = input.replace(/ஸி/g, "");
input = input.replace(/ஸீ/g, "");
input = input.replace(/ஸு/g, "");
input = input.replace(/ஸூ/g, "");
input = input.replace(/ஸெ/g, "");
input = input.replace(/ஸே/g, "");
input = input.replace(/ஸை/g, "");
input = input.replace(/ஸ்/g, "");
input = input.replace(/ஸ/g, "");

input = input.replace(/அ/g, "");
input = input.replace(/ஆ/g, "");
input = input.replace(/இ/g, "");
input = input.replace(/ஈ/g, "");
input = input.replace(/உ/g, "");
input = input.replace(/ஊ/g, "");
input = input.replace(/எ/g, "");
input = input.replace(/ஏ/g, "");
input = input.replace(/ஐ/g, "");
input = input.replace(/ஒ/g, "")
input = input.replace(/ஓ/g, "");
input = input.replace(/ஔ/g, "");
input = input.replace(/ஃ/g, "");

input = input.replace(/1/g, "");
input = input.replace(/2/g, "");
input = input.replace(/3/g, "");
input = input.replace(/4/g, "");
input = input.replace(/5/g, "");
input = input.replace(/6/g, "");
input = input.replace(/7/g, "");
input = input.replace(/8/g, "");
input = input.replace(/9/g, "");
input = input.replace(/0/g, "");

input = input.replace(/!/g, "");
input = input.replace(/'/g, "");
input = input.replace(/%/g, "");
input = input.replace(/\?/g, "");
input = input.replace(/"/g, "");
input = input.replace(/&/g, ""); 
input = input.replace(/\(/g, "");
input = input.replace(/\)/g, "");
input = input.replace(/\+/g, "");
input = input.replace(/,/g, "");
input = input.replace(/-/g, "");
input = input.replace(/:/g, "");
input = input.replace(/;/g, "");
input = input.replace(/=/g, "");
input = input.replace(/\//g, "");
input = input.replace(/\./g, "");
return input
      },
    
    }
  }
  </script>
  