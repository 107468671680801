<template>
    <div id="exclusive" ref="exclusive" class="flex-col mt-6 ">
        <div class="header text-gray-600 font-semibold text-2xl font-cv m-7"> — Exclusive —</div>

<div data-hs-carousel='{
    "loadingClasses": "opacity-0",
    "isAutoPlay": true
  }' class="relative mt-3">
  <div class="hs-carousel relative overflow-hidden w-full min-h-[600px]  rounded-lg">
    <div class="hs-carousel-body absolute top-0 bottom-0 start-0 flex flex-nowrap transition-transform duration-700 opacity-0">
      <div class="hs-carousel-slide">
        <div class="flex justify-center h-full  pl-4 pr-4 pb-2">
          <span class="text-4xl transition duration-700">
            <card imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/dt.png?alt=media&token=5c57d622-ddaa-490b-b4e4-3f529277b6bb"
            title="DESIGN THINKING "
            subtitle="InnoSculpt Studios: Mastering Tomorrow with Design Thinking"
            fullText="We specialize in applying Design Thinking expertise to tackle challenges in product design, business model canvas, and innovation ideas, providing effective solutions for your business."></card>
     
          </span>
        </div>
      </div>
      <div class="hs-carousel-slide">
        <div class="flex justify-center h-full  pl-4 pr-4 pb-2">
          <span class=" text-4xl transition duration-700">
       
            <card imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/3dmode.png?alt=media&token=f5be7a19-911d-49d1-9b00-d0bc80e9e5b7"
            title="3D MODELLING"
            subtitle="ModelCraft Dynamics: Shaping Tomorrow in 3D"
            fullText="We specialize in 3D modeling, using advanced software to bring ideas to life. From initial concept to final design, we handle everything. Our expertise includes reverse engineering, creating detailed drawings, and refining existing designs. In civil site designs, we focus on 3D elevation, approval plans, and 2D drawings. We use top software like AutoCAD, SolidWorks, NX CAD, CATIA, and Creo."></card> 
        
        </span>
        </div>
      </div>
      
      <div class="hs-carousel-slide">
        <div class="flex justify-center h-full  pl-4 pr-4 pb-2">
          <span class=" text-4xl transition duration-700">
            <card imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/3dmodeling.png?alt=media&token=979f6995-9d2b-4cac-9d73-ff5641454a30"
            title="3D PRINTING"
            subtitle="LayerCraft Innovations: Where Ideas Take Shape"
            fullText="Experience the extraordinary with our custom 3D printing solutions, creating unique products from tailored designs. We specialize in complex projects across various industries like healthcare, automotive, prototyping, jewelry, and gifts. With diverse material options like PLA, Carbon Fiber, PETT, Resin, TPU, and Nylon, we can turn your ideas into reality. Plus, explore our exclusive metal 3D printing for cutting-edge innovation"></card> 
          </span>
        </div>
      </div>
      <div class="hs-carousel-slide">
        <div class="flex justify-center h-full  pl-4 pr-4 pb-2">
          <span class=" text-4xl transition duration-700">
            
            <card imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/proto.png?alt=media&token=88cb7f70-6ec2-4e07-b555-3a90757b202e"
            title="Rapid prototyping Development "
            subtitle="SwiftCraft Prototypes: Crafting Tomorrow's Innovations Today"
            fullText="Discover the epitome of rapid prototyping at PrototypeSwift! We specialize in swiftly bringing concepts to life across diverse sectors, including agriculture, healthcare, industrial, and social projects. Elevate your innovation journey with unparalleled precision, speed, and expertise."></card>
          </span>
        </div>
      </div>
    </div>
  </div>

  <button type="button" class="hs-carousel-prev hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 start-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 ">
    <span class="text-2xl" aria-hidden="true">
      <svg class="size-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
      </svg>
    </span>
    <span class="sr-only">Previous</span>
  </button>
  <button type="button" class="hs-carousel-next hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 end-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 ">
    <span class="sr-only">Next</span>
    <span class="text-2xl" aria-hidden="true">
      <svg class="size-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
      </svg>
    </span>
  </button>

  <div class="hs-carousel-pagination flex justify-center absolute bottom-3 start-0 end-0 space-x-2">
    <span class="hs-carousel-active:bg-blue-700 hs-carousel-active:border-blue-700 size-3 border  rounded-full cursor-pointer"></span>
    <span class="hs-carousel-active:bg-blue-700 hs-carousel-active:border-blue-700 size-3 border  rounded-full cursor-pointer"></span>
    <span class="hs-carousel-active:bg-blue-700 hs-carousel-active:border-blue-700 size-3 border  rounded-full cursor-pointer"></span>
    <span class="hs-carousel-active:bg-blue-700 hs-carousel-active:border-blue-700 size-3 border  rounded-full cursor-pointer"></span>

  </div>
</div>
</div>
</template>

<script>
import card from './ex_container.vue';
export default {
components:{
    card
}
}
</script>