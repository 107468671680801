<template>
    <div class="flex  flex-col items-center overflow-hidden bg-opacity-25 justify-center pt-12 border border-gray-300  my-4 mx-4 rounded" data-aos="flip-left" data-aos-duration="5000">
      <img :src="img" alt="" class="h-1/2 w-1/2 text-white" data-aos="zoom-in" :data-aos-delay="delay" data-aos-duration="8000">
      <div class="font-normal text-3xl font-title text-center pt-6 pb-14 text-amber-950" data-aos="fade-up" :data-aos-delay="delay" data-aos-duration="5000">{{ name }} </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      img: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      delay:{
        type: String,
        required: true
      }
    }
  }
  </script>
  