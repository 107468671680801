<template>
    <div id="business" ref="business"  class="font-cv p-4 flex-col">
        <div class="header text-gray-600 font-semibold text-2xl"> — Our Business —</div>
<div class="md:flex md:flex-row md:justify-between lg:justify-center  md:items-start">
    <div class="lg:flex lg:flex-row lg:justify-start lg:items-start">
        <card imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/bconstruction.png?alt=media&token=7da3229b-6185-489c-b612-d1e9f71baa7c"
            title="Business Construction"
            subtitle="Empowering Your Business Ascent."
            fullText="Elevate your business from inception to success with our comprehensive services. Providing expert guidance for new entrepreneurs, we specialize in business development, offering support in business model canvas, location selection, equipment setup, strategic planning, legal services, and assistance in securing government support. Your journey from ground zero to new heights starts here."/>
            <card imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/soft.png?alt=media&token=ea0d51ac-b9ca-43d4-aee0-3aba687bc810"
            title="BUSINESS SOFTWARE"
            subtitle="Success with Software Solutions."
            fullText="Streamline operations and elevate success with our suite of business software solutions. From seamless e-commerce websites and applications to efficient billing and comprehensive business development tools – we've got your business covered."/>
            </div>
            <div class="lg:flex lg:flex-row lg:justify-start lg:items-start">
            <card imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/dm.png?alt=media&token=f45ff34a-fd83-4a4b-868e-e3356aa2bd7e"
            title="DIGITAL MARKETING"
            subtitle="Elevate Your Brand, Digitally Defined"
            fullText="we are digital growth experts, guiding you to online success. From impactful social media boosts and SEO strategies to compelling content creation and data-driven insights, let's embark on a journey to explore, engage, and elevate your brand together!"/>
            <card imageSrc="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/strategy.png?alt=media&token=06135451-d0dd-4eac-ae13-9188600feae0"
            title="Marketing and Strategy"
            subtitle="Your Success,Crafted with Precision"
            fullText="At Sustain Nova Tech Labs, we don't just navigate the business landscape; we sculpt success. From decoding market dynamics to crafting compelling brands, propelling digital footprints, and orchestrating impactful campaigns—we are the architects of your triumph. With strategic precision and innovative insights, we redefine possibilities, ensuring your journey with us is a roadmap to unparalleled success. Elevate your business with strategies that sustain and thrive—welcome to a future forged in excellence."/>
        </div>
        </div>
    </div>
</template>

<script>
import card from './container.vue';

export default {
components:{
    card
}
}
</script>