import { createApp } from 'vue'
import App from './App.vue'
import './tailwind.css'
import "preline/preline"
import 'animate.css'
import AOS from 'aos'
import 'aos/dist/aos.css'


const app = createApp(App)
AOS.init({
  once: false,
  mirror: false,
})



app.mount('#app')