<template>
  <navigation/>
  <footers 
      :icons="icons"/>    
</template>

<script>
import navigation from './components/navigation.vue';
import footers from '@/components/footer.vue'; 
import converter from '@/components/converter.vue'; 

export default {
  name: 'App',
  components: {
    navigation,
    footers,
    converter
  
  },
  data() {
    return {
     
      icons:[
     
        "https://firebasestorage.googleapis.com/v0/b/nsv-rubber-stamps.appspot.com/o/linkedin.png?alt=media&token=b24e43a7-2de7-42ef-942f-7c478a8153d4",
        "https://firebasestorage.googleapis.com/v0/b/nsv-rubber-stamps.appspot.com/o/instagram.png?alt=media&token=ea9d5128-cc7f-48d5-af08-9ade650bc967",
        "https://firebasestorage.googleapis.com/v0/b/nsv-rubber-stamps.appspot.com/o/facebook.png?alt=media&token=6e4b7ec0-a767-4ac0-bf43-340c6abb66b5",
        "https://firebasestorage.googleapis.com/v0/b/nsv-rubber-stamps.appspot.com/o/x.png?alt=media&token=fa84a378-da46-4a0d-8870-04987160b366",
        "https://firebasestorage.googleapis.com/v0/b/nsv-rubber-stamps.appspot.com/o/wa.png?alt=media&token=5fcb62fc-f55c-4c8a-9bc0-13bbfad36587"
      ]
    };
  }
}
</script>

<style>
  #app {
    margin: 0;
    padding: 0;
    background-color: white;
  }
</style>
