<template>
    <div id="projects" ref="projects" class=" p-4 items-center justify-center ">
        <div class="bg-black font-cv   rounded-tr-3xl sm:text-2xl rounded-bl-3xl p-5  text-white shadow-lg shadow-gray-200 mb-6" >
            — WE’RE COMMITTED TO DELIVER HIGH QUALITY PROJECTS —
        </div>
        <div class="sm:flex-row sm:flex flex-col ">
          
            <avatar img="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/research.png?alt=media&token=190b31d2-8bb0-4e94-8be8-d45554c6ea63" name="Market Research" delay="0"/>
            <avatar img="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/brand.png?alt=media&token=428baf30-11a3-4174-8ff1-42714344e1ea" name="Brand Development " delay="200" />
            <avatar img="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/social-media.png?alt=media&token=31a681f8-f0a2-459a-9f7b-2df2601c866d" name="Digital Marketing" delay="400"/>
            <avatar img="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/ads.png?alt=media&token=258780f5-6baf-4012-a70a-d877976cd40e" name="Advertising Campaigns" delay="600"/>

        </div>
        <div class="sm:flex-row sm:flex flex-col ">
          
            <avatar img="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/content-writing.png?alt=media&token=3692e2af-ea28-4e86-8d6b-4b69bae5a5a1" name="Content Strategy" delay="0"/>
            <avatar img="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/target-audience.png?alt=media&token=a52cb35a-06dc-4500-9ba6-a4f0acb1d90e" name="Customer Segmentation" delay="200"/>
            <avatar img="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/marketing-automation.png?alt=media&token=431d7872-6aa9-428d-87fe-48eeb9c10d00" name="Marketing Automation" delay="400"/>
            <avatar img="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/swot.png?alt=media&token=34e97046-bab6-4fbf-832a-ff67d5510755" name="SWOT Analysis" delay="600"/>


        </div>
        <!-- <div class="sm:flex-row sm:flex flex-col ">
            <avatar :img="require('../assets/competitive-analysis.png')" name="Competitive Analysis"/>
            <avatar :img="require('../assets/strategic.png')" name="Strategic Planning"/>
        

    </div> -->
  

        

    </div>
</template>


<script>
import avatar from './avatar.vue';

export default {
components:{
avatar
},

 

};
</script>


