<template>
    <div class="bg-white lg:w-[500px] md:w-[500px] flex items-center justify-center p-3 rounded mt-7 m-3 md:m-10 lg:m-3 lg:mt-7 flex-col shadow-lg shadow-gray-400" data-aos="flip-right"
       data-aos-easing="ease-out-cubic"
       data-aos-duration="1500"   :data-aos-delay="delay">
      <div class="rounded flex justify-between items-center">
        <img :src="imageSrc" class="object-cover rounded md:w-auto lg:w-auto md:h-64">
      </div>
      <div class="text-black font-medium text-2xl font-title text-center pt-3" data-aos="zoom-in"  data-aos-duration="3000" >
        {{ title }}
      </div>
      <div class="text-gray-400 font-medium md:w-auto lg:w-auto text-base font-subtitle text-center" data-aos="zoom-out"  data-aos-duration="6000" >
        {{ subtitle }}
      </div>
      <div class="text-black text-base font-light md:w-auto lg:w-auto text-center font-subtitle pt-1">
        {{ showFullText ? fullText : truncatedText }}
        <button @click="toggleText" class="text-amber-950 font-subtitle font-normal text-sm">{{ showFullText ? 'Show less' : 'Show more' }}</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      imageSrc: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: true
      },
      fullText: {
        type: String,
        required: true
      },
      delay:{
        type:String,
        required : true
      }
    },
    data() {
      return {
        showFullText: false,
        observer: null
      }
    },
    computed: {
      truncatedText() {
        return this.fullText.slice(0, 80) + '...';
      }
    },
  
    methods: {
      toggleText() {
        this.showFullText = !this.showFullText;
      },
     
    }
  }
  </script>