<template>
    
    <div class="bg-black   flex items-center justify-center"  data-aos="flip-up">
        <div class="border lg:w-[21rem] md:w-[25rem] flex px-10 py-10 h-20 rounded-md items-center justify-start m-4">
            <img :src="image" class="w-10" >
            <div class="font-subtitle  text-center font-bold pl-5 text-white text-lg"> {{ text }} </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    }
}
</script>
