<template>
  <div class="sticky top-0 z-50">
    <header
      class="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full border-b border-gray-500 text-sm py-4 md:py-5"
      :class="isScrolled ? 'bg-black' : 'bg-black'"
    >
      <nav
        class="relative max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
        aria-label="Global"
      >
        <div class="flex items-center justify-between">
<img src="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/fonta.jpg?alt=media&token=35eaaa52-aee0-4af8-bbd4-8c6a8b77137d" class="object-fill md:w-[60px] md:h-[40px] w-[40px] h-[35px]">

          <a
            class=" flex-none font-subtitle text-white text-2xl font-bold  md:px-5 animate-pulse "
            aria-label="Brand"
            > S N T L</a>
          
          
          <div class="sm:hidden">
            <button
              type="button"
              class="bg-black bg-opacity-5 hs-collapse-toggle size-9 flex justify-center items-center text-sm font-semibold rounded-lg border border-gray-200 text-white-800  disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              data-hs-collapse="#navbar-collapse-with-animation"
              aria-controls="navbar-collapse-with-animation"
              aria-label="Toggle navigation"
            >
              <svg
                class="hs-collapse-open:hidden flex-shrink-0 size-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="3" x2="21" y1="6" y2="6" />
                <line x1="3" x2="21" y1="12" y2="12" />
                <line x1="3" x2="21" y1="18" y2="18" />
              </svg>
              <svg
                class="hs-collapse-open:block hidden flex-shrink-0 size-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </div>
        </div>
        <div
          id="navbar-collapse-with-animation"
          class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block sm:ml-auto"
        >
          <div
            class="flex font-subtitle text-xl flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7 justify-end"
          >
            <a
              class="font-medium hover:text-gray-400 text-white dark:text-blue-500 cursor-pointer"
              aria-current="page"
              @click.prevent="setActiveLink('Home'); scrollTo('home')"
              :class="{ active: activeLink === 'Home' }"
              >Home</a
            >
            <a
              class="font-medium text-white hover:text-gray-400 cursor-pointer"
              @click.prevent="setActiveLink('About'); scrollTo('About')"
              :class="{ active: activeLink === 'About' }"
              >About</a
            >
            <a
              class="font-medium text-white hover:text-gray-400 cursor-pointer"
              @click.prevent="setActiveLink('Products'); scrollTo('products')"
              :class="{ active: activeLink === 'Products' }"
              >Products</a
            >
            <a
              class="font-medium text-white hover:text-gray-400 cursor-pointer"
              @click.prevent="setActiveLink('Exclusive'); scrollTo('exclusive')"
              :class="{ active: activeLink === 'Exclusive' }"
              >Exclusive</a
            >
            <a
              class="font-medium text-white hover:text-gray-400 cursor-pointer"
              @click.prevent="setActiveLink('Business'); scrollTo('business')"
              :class="{ active: activeLink === 'Business' }"
              >Business</a
            >
            <a
              class="font-medium text-white hover:text-gray-400 cursor-pointer"
              @click.prevent="setActiveLink('Projects'); scrollTo('projects')"
              :class="{ active: activeLink === 'Projects' }"
              >Projects</a
            >
            <a
              class="font-medium text-white hover:text-gray-400 cursor-pointer"
              @click.prevent="setActiveLink('Contact Us'); scrollTo('contact')"
              :class="{ active: activeLink === 'Contact Us' }"
              >Contact Us</a
            >
          </div>
        </div>
      </nav>
    </header>
  </div>

  <section id="home">
    <intro/>
  </section>

  <section id="About">
    <about/>
  </section>

  <section id="products">
    <products/> 
  </section>

  <section id="exclusive">
    <exclusive/>
  </section>

  <section id="business">
    <business/>
  </section>

  <section id="projects">
    <projects/>
  </section>

  <section id="contact">
    <contact/>
  </section>
</template>

<script>
import intro from '@/components/intro.vue'; 
import about from '@/components/about.vue'; 
import products from '@/components/products.vue';
import exclusive  from '@/components/exclusive.vue';
import business from '@/components/buisness.vue'; 
import projects from '@/components/projects.vue'; 
import contact from '@/components/contact_us.vue'; 
export default {
  components:{
    intro,
    about,
    products,
    exclusive,
    business,
    projects,
    contact
  },
  data() {
    return {
      isScrolled: false,
      activeLink: null,
      sections: {
        home: null,
        about: null,
        products: null,
        exclusive: null,
        business: null,
        projects: null,
        contact: null,
      },
    };
  },
  mounted() {
    this.getSectionPositions();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop - 70,
          behavior: 'smooth'
        });
      }
    },
    setActiveLink(link) {
      this.activeLink = link;
    },
    getSectionPositions() {
      this.sections.home = document.getElementById('home')?.offsetTop || 0;
      this.sections.about = document.getElementById('About')?.offsetTop || 0;
      this.sections.products = document.getElementById('products')?.offsetTop || 0;
      this.sections.exclusive = document.getElementById('exclusive')?.offsetTop || 0;
      this.sections.business = document.getElementById('business')?.offsetTop || 0;
      this.sections.projects = document.getElementById('projects')?.offsetTop || 0;
      this.sections.contact = document.getElementById('contact')?.offsetTop || 0;
    },
    handleScroll() {
      const scrollPosition = window.pageYOffset + 70;
      const sections = Object.entries(this.sections);
      let lastSectionPosition = 0;

      for (const [sectionName, sectionPosition] of sections) {
        if (scrollPosition >= sectionPosition) {
          this.activeLink = sectionName.charAt(0).toUpperCase() + sectionName.slice(1);
        }
        lastSectionPosition = sectionPosition;
      }

      if (scrollPosition >= lastSectionPosition) {
        this.activeLink = "Contact Us";
      }
    },
  },
};
</script>

<style scoped>
.active {
  color: #f97316 !important;
}
</style>