<template>
  <div id="contact" ref="contact" class="max-w-[85rem] px-4 py-10 sm:px-6 bg-black lg:px-8 lg:py-14 mx-auto w-full">
    <div class="max-w-2xl lg:max-w-5xl mx-auto">
      <div class="text-center">
        <h1 class="text-3xl font-cv text-white font-bold sm:text-4xl dark:text-white">
          — Contact us —
        </h1>
        <p class="mt-1 dark:text-gray-400 font-subtitle text-gray-400">
          We'd love to talk about how we can help you.
        </p>
      </div>

      <div class="mt-12 grid items-center font-subtitle lg:grid-cols-2 gap-6 lg:gap-16" data-aos="fade-right">
        <div class="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-8 dark:border-gray-700">
          <h2 class="mb-8 text-xl font-semibold dark:text-gray-200 text-white">
            Fill in the form
          </h2>

          <form>
            <div class="grid gap-4">
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <label for="hs-firstname-contacts-1" class="sr-only">First Name</label>
                  <input
                    type="text"
                    v-model="firstName"
                    name="hs-firstname-contacts-1"
                    id="hs-firstname-contacts-1"
                    class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                    placeholder="First Name"
                    required
                  />
                  <p v-if="firstNameError" class="text-red-500 text-sm mt-1">{{ firstNameError }}</p>
                </div>

                <div>
                  <label for="hs-lastname-contacts-1" class="sr-only">Last Name</label>
                  <input
                    type="text"
                    v-model="lastName"
                    name="hs-lastname-contacts-1"
                    id="hs-lastname-contacts-1"
                    class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                    placeholder="Last Name"
                    required
                  />
                  <p v-if="lastNameError" class="text-red-500 text-sm mt-1">{{ lastNameError }}</p>
                </div>
              </div>

              <div>
                <label for="hs-email-contacts-1" class="sr-only">Email</label>
                <input
                  type="email"
                  v-model="email"
                  name="hs-email-contacts-1"
                  id="hs-email-contacts-1"
                  autocomplete="email"
                  class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                  placeholder="Email"
                  required
                />
                <p v-if="emailError" class="text-red-500 text-sm mt-1">{{ emailError }}</p>
              </div>

              <div>
                <label for="hs-phone-number-1" class="sr-only">Phone Number</label>
                <input
                  type="text"
                  v-model="phoneNumber"
                  name="hs-phone-number-1"
                  id="hs-phone-number-1"
                  class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                  placeholder="Phone Number"
                  required
                />
                <p v-if="phoneNumberError" class="text-red-500 text-sm mt-1">{{ phoneNumberError }}</p>
              </div>

              <div>
                <label for="hs-about-contacts-1" class="sr-only">Details</label>
                <textarea
                  v-model="details"
                  id="hs-about-contacts-1"
                  name="hs-about-contacts-1"
                  rows="4"
                  class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                  placeholder="Details"
                  required
                ></textarea>
                <p v-if="detailsError" class="text-red-500 text-sm mt-1">{{ detailsError }}</p>
              </div>
            </div>

            <div class="mt-4 grid">
              <button
                @click.prevent="sendEmail"
                type="button"
                class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-orange-600 text-white hover:bg-orange-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                Send inquiry
              </button>
            </div>

            <div class="mt-3 text-center">
              <p class="text-sm text-gray-500">
                We'll get back to you in 1-2 business days.
              </p>
            </div>
          </form>
        </div>

        <div class="">
          <div class="flex gap-x-7 px-2 py-4">
            <div class="grow">
              <custom
                image="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/feature%20(1).png?alt=media&token=89a56fba-cf68-4251-bc45-43f93f319002"
                text="Complete Customization "
              />
            </div>
          </div>

          <div class="flex gap-x-7 px-2 py-4">
            <div class="grow text-white hj" >
              <custom
                image="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/achievement.png?alt=media&token=f2f1c816-3f87-4295-8169-3dea7738ab69"
                text="Quality Assurance"
              />
            </div>
          </div>

          <div class="flex gap-x-7 px-2 py-4">
            <div class="grow">
              <custom
                image="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/crm%20(1).png?alt=media&token=a0cf84c3-09d9-4657-9697-ef7529722208"
                text="Customer Relationships"
              />
            </div>
          </div>

          <div class="flex gap-x-7 px-2 py-4 hj">
            <div class="grow">
              <custom
                image="https://firebasestorage.googleapis.com/v0/b/mapview-aafa3.appspot.com/o/delivery-man.png?alt=media&token=c5575985-00b7-4aff-b5cd-7aa7173a0193"
                text="On-Time Delivery"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isLoading" class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen">
      <div class="bg-white rounded-lg px-4 py-5 shadow dark:bg-gray-800">
        <div class="flex items-center px-3">
          <div class="loader"></div>
          
          <h3 class="text-lg font-subtitle leading-tight dark:text-white">Please wait...</h3>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showSuccessPage" class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen">
      <div class="bg-white rounded-lg px-4 py-5 shadow dark:bg-gray-800">
        <div class="flex items-center">
          <div class="mr-3">
            <svg class="h-10 w-10 text-green-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
          </div>
          <h3 class="text-lg font-subtitle leading-tight dark:text-white">Thank You for Visiting !</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.hj{
  color:white
}
.loader {
  margin-right: 20px;

  border: 10px solid #f3f3f3;
  border-top: 10px solid orangered; 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
<script>
import axios from 'axios'
import custom from '@/components/customization.vue'

export default {
  components: {
    custom,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      details: '',
      firstNameError: '',
      lastNameError: '',
      emailError: '',
      phoneNumberError: '',
      detailsError: '',
      isLoading: false,
      showSuccessPage: false,
      successPageTimeout: null
    }
  },
  methods: {
    async sendEmail() {
      this.firstNameError = ''
      this.lastNameError = ''
      this.emailError = ''
      this.phoneNumberError = ''
      this.detailsError = ''
      this.isLoading = true
      this.showSuccessPage = false

      if (this.firstName.trim() === '') {
        this.firstNameError = 'First name is required'
      }
      if (this.email.trim() === '') {
        this.emailError = 'Email is required'
      } else {
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (!emailRegex.test(this.email)) {
          this.emailError = 'Invalid email address'
        }
      }
      if (this.phoneNumber.trim() === '') {
        this.phoneNumberError = 'Phone number is required'
      }
      if (this.details.trim() === '') {
        this.detailsError = 'Details are required'
      }

      if (this.firstNameError || this.lastNameError || this.emailError || this.phoneNumberError || this.detailsError) {
        this.isLoading = false
        return
      }

      try {
        const formData = {
          fullName: `${this.firstName.trim()} ${this.lastName.trim()}`,
          email: this.email.trim(),
          mobile: this.phoneNumber.trim(),
          message: this.details.trim()
        }
        const response = await axios.post('https://mailapi-fgfi.onrender.com/send-email', formData)
        console.log('Email sent successfully')
        console.log(response.data)
        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.phoneNumber = ''
        this.details = ''
        this.isLoading = false
        this.showSuccessPage = true
        this.successPageTimeout = setTimeout(() => {
          this.showSuccessPage = false
        }, 3000)
      } catch (error) {
        console.error(error)
        this.isLoading = false
      }
    }
  },
  beforeUnmount() {
    clearTimeout(this.successPageTimeout)
  }
}
</script>