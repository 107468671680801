<template>
    <div class="inline-block relative">
      <span
        v-for="(char, index) in displayedText"
        :key="index"
        class="inline-block"
        :class="{
          'opacity-0': !isAnimated && index === displayedText.length - 1,
          'opacity-100': isAnimated,
        }"
      >
        {{ char === ' ' ? '&nbsp;' : char }}
      </span>
      <span
        class="inline-block border-r-2 border-black animate-blink"
        :class="{
          'opacity-100': isAnimated,
          'opacity-0': !isAnimated,
        }"
      >&nbsp;</span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TypewriterAnimation',
    props: {
      text: {
        type: String,
        required: true,
      },
      typingSpeed: {
        type: Number,
        default: 100,
      },
    },
    data() {
      return {
        isAnimated: false,
        displayedText: '',
        currentIndex: 0,
      };
    },
    mounted() {
      this.startAnimation();
    },
    methods: {
      startAnimation() {
        this.isAnimated = true;
        this.typeText();
      },
      typeText() {
        if (this.currentIndex < this.text.length) {
          this.displayedText += this.text.charAt(this.currentIndex);
          this.currentIndex++;
          setTimeout(this.typeText, this.typingSpeed);
        }
      },
    },
  };
  </script>
  
  <style>
  /* .animate-blink {
    animation: blink 0.2s infinite;
  } */
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  </style>